<template>
  <div v-if="isShow" class="modal fade d-block show" tabindex="-1">
    <div class="modal-backdrop fade show" @click="close" style="z-index: -1"> </div>
    <div class="modal-dialog modal-dialog-centered" style="margin: 0 auto; padding: 10px; height: 100%; max-width: 500px;">
      <div class="modal-content" style="max-height: 80vh; overflow-y: auto;">
        <div class="modal-header pb-0 mb-3">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="close"></button>
          <slot>

          </slot>
        </div>
        <div class="modal-body pt-0 pb-4">
          <div class="row justify-content-center text-center">
            <div class="col-8">
              <button class="btn btn-md btn-primary w-100 mb-3" @click="ok">
                  <slot name="button"></slot>
              </button>
              <a href="javascript:void(0)" class="link" @click="close">{{$t('cancelbtn')}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['isShow'],
  data() {
    return {

    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    ok() {
      this.$emit('ok');
    }
  },
}
</script>

<style scoped>

</style>
