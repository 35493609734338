<template>
  <tr class="smart-table__row">
    <td class="smart-table__cell">
      <div class="cryptocurrency">
        <img class="cryptocurrency__img" :src="logo"/>
        <div class="cryptocurrency__name ms-2">{{data.symbol.toUpperCase()}}</div>
      </div>
    </td>
    <td class="smart-table__cell justify-content-end"><span>{{updateAmount(total)}}</span></td>
    <td class="smart-table__cell justify-content-end"><span>{{updateAmount(data.amount)}}</span></td>
    <td class="smart-table__cell justify-content-end"><span>{{updateAmount(data.frozen)}}</span></td>
    <td class="smart-table__cell justify-content-end"><span>{{updateAmount(base)}}</span></td>
    <td class="smart-table__cell justify-content-end" style="min-width: 225px;">
      <template v-if="data.symbol.toLowerCase() === 'usdt'">
        <coin-action v-for="item in actions" :data="item" :symbol="data.symbol"></coin-action>
      </template>
      <template v-else>
        <coin-action v-for="item in actionsLimited" :data="item" :symbol="data.symbol"></coin-action>
      </template>
    </td>
  </tr>
</template>

<script>
import logoUSDT from '@/assets/img/coins/usdt.svg';
import logoSTAB from '@/assets/img/coins/stab.svg';
import logoREND from '@/assets/img/coins/rend.svg';
import CoinAction from '@/components/WalletComponents/CoinAction';

export default {
  components: {
    CoinAction,
  },
  name: 'CoinItem',
  props: ['data', 'hideAmount'],
  data() { return {
    actions: ['deposit', 'transfer', 'withdrawChoose'],
    actionsLimited: ['transfer',],
  }},
  computed: {
    url() {
      return (this.data.id !== 1) ? '#!/swap/' + this.data.symbol : location.href;
    },
    total() {
      return (parseFloat(this.data.amount||0)+parseFloat(this.data.frozen||0)).toFixed(6);
    },
    base() {
      return ((parseFloat(this.data.amount||0)+parseFloat(this.data.frozen||0))*parseFloat(this.data.rate||0)).toFixed(6);
    },
    logo() {
      if (this.data.symbol.toLowerCase() === 'usdt') {
        return logoUSDT;
      } else if (this.data.symbol.toLowerCase() === 'stab') {
        return logoSTAB;
      } else if (this.data.symbol.toLowerCase() === 'rend') {
        return logoREND;
      }
    },
  },
  methods: {
    updateAmount(amount) {
      if (this.hideAmount) {
        return '*'.repeat(amount.toString().length);
      } else {
        return amount;
      }
    },
  }
}
</script>

<style scoped>

</style>
