<template>
<tr class="smart-table__row">
  <td class="smart-table__cell" data-column-id="date"><span>{{date_converted}}</span></td>
  <td class="smart-table__cell" data-column-id="cryptocurrency">
    <div class="cryptocurrency">
      <img class="cryptocurrency__img" :src="require('@/assets/img/coins/'+ symbol.toLowerCase() +'.svg')">
      <div class="cryptocurrency__name ms-2">{{symbol.toUpperCase()}}</div>
    </div>
  </td>
  <td class="smart-table__cell" data-column-id="type">
    <span v-if="data.mode == 0">{{ $t("withdraw_type_usdt") }}</span>
    <span v-if="data.mode == 1">{{ $t("withdraw_type_stab") }}</span>
  </td>
  <td class="smart-table__cell" data-column-id="sum"><span class="minus">{{data.amount}}</span></td>
  <td class="smart-table__cell" data-column-id="transaction">
    <a :href="url" target="_blank">
      <template v-if="data.hash">
        {{data.hash.slice(0, 5)}}...{{ data.hash.slice(data.hash.length - 1, 5) }}
      </template>
    </a>
  </td>
  <td class="smart-table__cell" data-column-id="status">
    <!--<div>
      <span v-if="!data.confirmed">{{ $t('Not Confirmed') }}</span>
      <span v-else-if="!data.done">{{ $t('In processing') }}</span>
      <span v-else>{{ $t('Completed') }}</span>
    </div>
    <button v-if="!data.confirmed" @click="modalConfirm" type="button" class="btn btn-info" style="margin:0 0.5rem 0 0.5rem; padding: 4px 8px;">Подтвердить</button>-->
    <button v-if="!data.confirmed_administration" @click="modalCancel" type="button" class="btn btn-info" style="margin:0 0.5rem 0 0.5rem; padding: 4px 8px;">{{$t('cancel_btn')}}</button>
  </td>
</tr>
</template>

<script>
import {commitToggleModal} from '@/store/main/mutations';

export default {
  name: 'HistoryWithdraw',
  props: [ 'data', 'coins' ],
  data() {
    return {
      symbol: this.coins[ this.data.coin - 1 ].symbol,
    }
  },
  computed: {
    date_converted: function() {
      return this.stamp2date( this.data.stamp );
    },
    url() {
      return `https://${this.symbol.toLowerCase()}.tokenview.com/ru/tx/${this.data.hash}`;
    }
  },
  methods: {
    modalConfirm() {
      this.data.modalType = "confim";
      this.data.symbol = this.symbol.toUpperCase();
      commitToggleModal(this.$store, {modal:'withdraw', data: this.data});
    },

    modalCancel() {
      this.data.feedback = false;
      this.data.modalType = "cancel";
      this.data.symbol = this.symbol.toUpperCase();
      commitToggleModal(this.$store, {modal:'withdraw', data: this.data});
    }
  }
}
</script>

<style scoped>

</style>
