<template>
<main class="main">
  <div class="content">
    <div class="container content__container content__container--mw100">
      <!--wallet.begin-->
      <div class="wallet">
        <div class="wallet__head">
          <h1 class="wallet__title">{{ $t("wallettitle") }}</h1>
          <div class="tabs wallet__tabs">
            <ul class="nav nav-tabs">
              <li class="nav-item" v-for="(item, index) in tab.main.items" :key="`walletMain-${index}`">
                <button class="nav-link" :class="{ active: tab.main.active === index }" type="button" @click="showTab('main', index)">
                  {{ $t(tab.main.items[index]) }}
                </button>
              </li>
            </ul>
          </div>
          <button type="button" name="button" class="btn btn-info" style="min-height:26px; padding: 0 1em; margin:0.5em 0;" @click="modalWithdrawStabilityin.status = true">{{ $t("reservation_title") }}</button>
          <div class="wallet__btns">
            <!-- <button class="btn btn-info wallet__btn" @click="deposit">
                {{$t('deposit')}}
              </button>
              <button class="btn btn-gray-outline wallet__btn" @click="transfer">
                {{$t('transfer')}}
              </button>
              <button class="btn btn-gray-outline wallet__btn" @click="withdraw">
                {{$t('withdraw')}}
              </button> -->
          </div>
        </div>
        <div class="wallet__body">
          <div class="tab-content">
            <div class="tab-pane fade show" :class="{ active: tab.main.active === 0 }">

              <div class="wallet-payid">
                {{$t('your_payid')}}:<br>
                <span>{{ payid }}</span>
              </div>

              <div class="wallet__items">
                <div class="wallet__item">
                  <!--toggle.begin-->
                  <label class="toggle wallet__toggle-hide-balance d-flex align-items-center">
                    <input type="checkbox" name="hideBalance" v-model="hideAmount" class="toggle__control sr-only" />
                    <span class="toggle__text me-2">
                      {{ $t("hidebalance") }}
                    </span>
                    <span class="toggle__icon"></span>
                  </label>
                  <!--toggle.end-->
                  <!--balance.begin-->
                  <div class="balance wallet__balance">
                    <div class="balance__item">
                      <div class="balance__caption">
                        {{ $t("freebalance") }}
                      </div>
                      <div class="balance__main">
                        <div class="balance__num hand" @click="getCoins">
                          {{ updateAmount(total.free) }}
                        </div>
                        <div class="balance__cur">{{ symbol.base }}</div>
                      </div>
                    </div>
                    <div class="balance__item">
                      <div class="balance__caption">{{ $t("frozen") }}</div>
                      <div class="balance__main">
                        <div class="balance__num">
                          {{ updateAmount(total.frozen) }}
                        </div>
                        <div class="balance__cur">{{ symbol.base }}</div>
                      </div>
                    </div>
                    <div class="balance__item">
                      <div class="balance__caption">PNL {{ $t("per_yesterday") }}</div>
                      <div class="balance__main">
                        <div class="balance__num">
                          {{ updateAmount(total.pnl) }}
                        </div>
                        <div class="balance__cur">{{ symbol.base }}</div>
                      </div>
                    </div>
                  </div>
                  <!--balance.end-->
                </div>
                <div class="wallet__item">
                  <div class="wallet__item-head d-flex align-items-center">
                    <div class="wallet__item-column order-md-2">
                      <!--toggle.begin-->
                      <label class="toggle wallet__low-value-assets d-flex align-items-center">
                        <input type="checkbox" name="hideLowValueAssets" class="toggle__control sr-only" />
                        <span class="toggle__text me-2">{{ $t("hidelowbalance")
                          }}</span>
                        <span class="toggle__icon"></span>
                      </label>
                      <!--toggle.end-->
                    </div>
                    <div class="wallet__item-column order-md-1">
                      <!--form-search.begin-->
                      <form action="#" class="form-search wallet__form-search" method="POST">
                        <input type="search" name="searchCoin" class="form-search__control" :placeholder="$t('searchcoin')" required />
                        <button class="btn form-search__button">
                          <svg class="form-search__icon">
                            <use xlink:href="#i-search"></use>
                          </svg>
                        </button>
                      </form>
                      <!--form-search.end-->
                    </div>
                  </div>
                  <div class="wallet__item-body">
                    <div class="smart-table smart-table--wallet">
                      <table class="table smart-table__table">
                        <thead class="smart-table__thead">
                          <tr class="smart-table__row">
                            <th class="smart-table__cell" data-column-id="cryptocurrency">
                              <div class="smart-table__sort-by">
                                {{ $t("coin") }}
                              </div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="all">
                              <div class="smart-table__sort-by">
                                {{ $t("total") }}
                              </div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="available">
                              <div class="smart-table__sort-by">
                                {{ $t("freebalance") }}
                              </div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="inorder">
                              <div class="smart-table__sort-by">
                                {{ $t("frozen") }}
                              </div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="cost-btc">
                              <div class="smart-table__sort-by">
                                {{ $t("baseamount") }} {{ symbol.base }}
                              </div>
                            </th>
                            <th class="smart-table__cell text-right" data-column-id="status">
                              <div class="smart-table__sort-by">&nbsp;</div>
                            </th>
                          </tr>
                        </thead>
                        <tbody class="smart-table__tbody">
                          <template style="display: contents">
                            <coin-item v-for="(item, index) in coins" :hideAmount="hideAmount" :data="item" :key="`coinItems-${index}`"></coin-item>
                          </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--deposit.start-->
            <div class="tab-pane fade show" :class="{ active: tab.main.active === 1 }">
              <div class="wallet__item">
                <div class="wallet__item-body">
                  <div class="smart-table smart-table--wallet-history">
                    <table class="table smart-table__table">
                      <thead class="smart-table__thead">
                        <tr class="smart-table__row">
                          <th class="smart-table__cell" data-column-id="date">
                            <div class="smart-table__sort-by">
                              {{ $t("stamp") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="cryptocurrency">
                            <div class="smart-table__sort-by">
                              {{ $t("coin") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="sum">
                            <div class="smart-table__sort-by">
                              {{ $t("amount") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="transaction">
                            <div class="smart-table__sort-by">
                              {{ $t("txhash") }}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="smart-table__tbody">
                        <template style="display: contents">
                          <history-deposit v-for="(item, index) in history.deposit" :data="item" :coins="coins" :key="`historyDeposit-${index}`"></history-deposit>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--deposit.end-->
            <!--transfer.start-->
            <div class="tab-pane fade show" :class="{ active: tab.main.active === 2 }">
              <div class="wallet__item">
                <div class="wallet__item-body">
                  <div class="smart-table smart-table--wallet-history">
                    <table class="table smart-table__table">
                      <thead class="smart-table__thead">
                        <tr class="smart-table__row">
                          <th class="smart-table__cell" data-column-id="date">
                            <div class="smart-table__sort-by">
                              {{ $t("stamp") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="cryptocurrency">
                            <div class="smart-table__sort-by">
                              {{ $t("coin") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="sum">
                            <div class="smart-table__sort-by">
                              {{ $t("amount") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="transaction">
                            <div class="smart-table__sort-by">
                              {{ $t("txhash") }}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="smart-table__tbody">
                        <template style="display: contents">
                          <history-transfer v-for="(item, index) in history.transfer" :data="item" :coins="coins" :key="`historyTransfer-${index}`"></history-transfer>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--transfer.end-->
            <!--withdraw.start-->
            <div class="tab-pane fade show" :class="{ active: tab.main.active === 3 }">
              <div class="wallet__item">
                <div class="wallet__item-body">
                  <div class="smart-table smart-table--wallet-history">
                    <table class="table smart-table__table">
                      <thead class="smart-table__thead">
                        <tr class="smart-table__row">
                          <th class="smart-table__cell" data-column-id="date">
                            <div class="smart-table__sort-by">
                              {{ $t("stamp") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="cryptocurrency">
                            <div class="smart-table__sort-by">
                              {{ $t("coin") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="type">
                            <div class="smart-table__sort-by">
                              {{ $t("withdraw_type") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="sum">
                            <div class="smart-table__sort-by">
                              {{ $t("amount") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="transaction">
                            <div class="smart-table__sort-by">
                              {{ $t("txhash") }}
                            </div>
                          </th>
                          <th class="smart-table__cell" data-column-id="status">
                            <div class="smart-table__sort-by">
                              {{ $t("status") }}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody class="smart-table__tbody">
                        <template style="display: contents">
                          <history-withdraw v-for="(item, index) in history.withdraw" :data="item" :coins="coins" :key="`historyWithdraw-${index}`"></history-withdraw>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--withdraw.end-->
          </div>
        </div>
        <div class="wallet__foot">
          <!--pagination.begin-->
          <!--<pagination :data="{}"></pagination>-->
          <!--pagination.end-->
        </div>
      </div>
      <!--wallet.end-->
    </div>
  </div>

  <base-modal :isShow="modalWithdrawStabilityin.status" @close="modalWithdrawStabilityinClose" @ok="modalWithdrawStabilityinOk">
    <div class="w-100">
      <h2 class="modal-title mb-2">{{ $t("reservation_title") }}</h2>
      <div class="d-flex justify-content-between mb-3">
        <div class="modal-rend__text text-white">REND</div>
        <div class="modal-rend__value text-white">{{ total.free }}</div>
      </div>
      <div class="control control--md modal-rend__control mb-3">
        <input type="number" name="modalRend" class="control__input" placeholder="Введите сумму" required v-model:value="modalWithdrawStabilityin.amount" />
        <span class="control__cur">REND</span>
      </div>
      <div class="mb-4">
        <div class="range form__range">
          <input type="range" class="range__control" name="range" step="25" min="0" :max="total.free" v-model:value="modalWithdrawStabilityin.amount" />
        </div>
      </div>
    </div>
    <template v-slot:button> {{ $t("transfer_btn") }} </template>
  </base-modal>

</main>
</template>

<script>
import { api } from "@/api.ts";
import axios from 'axios';
import HistoryWithdraw from "@/components/WalletComponents/HistoryWithdraw";
import HistoryDeposit from "@/components/WalletComponents/HistoryDeposit";
import HistoryTransfer from "@/components/WalletComponents/HistoryTransfer";
import CoinItem from "@/components/WalletComponents/CoinItem";
import BaseModal from "@/components/modals/BaseModal";
import { commitToggleModal } from "@/store/main/mutations";
import { readUser, readUserFp, readCoins, readModalShow } from "@/store/main/getters";
import { commitSetCoins} from "../../store/main/mutations";

const cfg = {
  coin: 'USDT',
  base: 'REND',
};

export default {
  components: {
    HistoryWithdraw,
    HistoryDeposit,
    HistoryTransfer,
    CoinItem,
    BaseModal
  },
  name: "Wallet",
  props: [ "reloadWallet" ],
  data() {
    return {
      payid: '',
      auth: false,
      symbol: {
        base: cfg.base,
        coin: cfg.coin
      },
      disabled: false,
      coins: [],
      explorer: "",
      total: {
        frozen: 0,
        free: 0,
        pnl: 0
      },
      tab: {
        main: {
          active: 0,
          items: [ "balance", "historydeposit", "historytransfer", "historywithdraw" ]
        }
      },
      history: {
        active: [],
        user: [],
        all: [],
        deposit: [],
        transfer: [],
        withdraw: []
      },
      hideAmount: false,
      modalDeposit: false,
      modalTransfer: false,
      modalWithdraw: false,
      modalWithdrawStab: false,
      modalWithdrawStabilityin: {
        status: false,
        amount: 0
      }
    };
  },
  computed: {
    user() {
      return readUser( this.$store );
    }
  },
  methods: {
    checkAuth() {
      if ( this.user.hash ) return ( this.auth = true );
      commitToggleModal( this.$store, "login" );
      return false;
    },

    commitCoins() {
      api( "wallet", "getCoins", {
        type: "all"
      } ).then( r => {
        console.log( "commitCoins", r );
        if ( r.code === 1 ) return this.$noty.error(
          ( this.$t( r.data.error ) || this.$t( "smthwrong" ) ) + ( r.data.msg || "" ), {
            timeout: 4000,
            progressBar: true
          } );
        if ( r.code !== 2 || typeof r.data !== "object" ) return false;
        commitSetCoins( this.$store, r.data.coins );
        this.getCoins();
      } );
    },

    getCoins() {
      const coins = readCoins( this.$store );

      this.coins = coins;

      const baseCoin = coins.filter(item => item.symbol.toLowerCase() === this.symbol.base.toLowerCase());
      this.total.free = Number(baseCoin[0].amount).toFixed(6);
      this.total.frozen = Number(baseCoin[0].frozen).toFixed(6);
    },

    getHistory( type = "all", symbol = "USDT" ) {
      this.history[ type ] = [];
      this.$noty.info( this.$t( "loading" ), { timeout: 4000, progressBar: true } );
      api( "wallet", "getHistory", {
        type: type,
        symbol: symbol
      } ).then( r => {
        console.log( "getHistory", r );
        if ( r.code === 1 ) return this.$noty.error(
          ( this.$t( r.data.error ) || this.$t( "smthwrong" ) ) + ( r.data.msg || "" ), {
            timeout: 4000,
            progressBar: true
          } );
        if ( r.code !== 2 || typeof r.data !== "object" ) return false;
        if ( r.data.history ) {
          this.history[ type ] = r.data.history[ type ] ? r.data.history[ type ] : [];
        }
        this.explorer = r.data.explorer || "";
      } );
    },

    sendWithdrawStabilityin() {
      this.$noty.info( this.$t( "wait" ), {
        timeout: 4000,
        progressBar: true
      } );
      return api( "wallet", "transferToAnotherPlatform", {
        amount: this.modalWithdrawStabilityin.amount
      } ).then( r => {
        console.log( "transferToAnotherPlatform", r );
        if ( r.data.ok ) {
          this.$noty.success( this.$t( "success" ), {
            timeout: 2000,
            progressBar: true
          } );
          return true;
        } else {
          this.$noty.error( r.data.error, {
            timeout: 2000,
            progressBar: true
          } );
        }
      } );
    },

    deposit() {
      commitToggleModal( this.$store, "deposit" );
    },

    transfer() {
      commitToggleModal( this.$store, "transfer" );
    },

    withdraw() {
      commitToggleModal( this.$store, "withdraw" );
    },

    withdrawStabilityin() {
      commitToggleModal( this.$store, "withdrawStabilityin" );
    },

    showTab( t, n ) {
      if ( typeof this.tab[ t ] == "undefined" || typeof n != "number" ) return false;
      this.tab[ t ].active = n;
      if ( t === "main" ) switch ( n ) {
        case 0:
          this.getCoins();
          break;
        case 1:
          this.getHistory( "deposit", "USDT" );
          break;
        case 2:
          this.getHistory( "transfer", "all" );
          break;
        case 3:
          this.getHistory( "withdraw", "USDT" );
          break;
      }
    },

    updateAmount( amount ) {
      if ( typeof amount !== "undefined" && amount !== null ) {
        if ( this.hideAmount ) {
          return "*".repeat( amount.toString().length );
        } else {
          return amount;
        }
      } else {
        return amount;
      }
    },

    modalWithdrawStabilityinClose() {
      this.modalWithdrawStabilityin.status = false;
    },
    async modalWithdrawStabilityinOk() {
      const response = await this.sendWithdrawStabilityin();
      if ( response === true ) {
        this.commitCoins();
        this.getHistory( "transfer", "REND" );
        this.modalWithdrawStabilityin.status = false;
      }
    },

    profileInfo(){
      axios.post(this.$store._modules.root.state.main.API_URL+'/user',
          {method: 'profileInfo',
            data: { fp: readUserFp(this.$store), uid: this.user.uid}},{headers: {'Authorization':'Bearer ' + this.user.hash}}).then(r => {
        console.log('profileInfo', r.data);
        if (typeof(r.data.data.profile)=='object') this.payid = r.data.data.profile.payid;
      });
    },
  },

  created() {
    if (this.checkAuth()) {
      this.commitCoins();

      this.unwatch = this.$store.watch((state, getters) => getters.modalShow,
        (newValue, oldValue) => {
          if(newValue === '' && oldValue.modal === 'withdraw')
            this.getHistory( "withdraw", "USDT" );
        },
      );
    }
    this.profileInfo();
  },

  beforeDestroy() {
    this.unwatch();
  },
};
</script>

<style scoped>
  .wallet-payid {
    font-size: 15px;
    font-weight: 600;
    padding: 15px 8px 2px 8px;
    color: #fff;
  }
  .wallet-payid > span {
    font-size: 24px;
    letter-spacing: 1px;
  }
</style>
