import { render, staticRenderFns } from "./HistoryDeposit.vue?vue&type=template&id=4549e4e6&scoped=true&"
import script from "./HistoryDeposit.vue?vue&type=script&lang=js&"
export * from "./HistoryDeposit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4549e4e6",
  null
  
)

export default component.exports