<template>
  <div>
    <a class="link wallet__link hand" @click="doIt">{{title}}</a>
  </div>
</template>

<script>
import {commitSetSymbol, commitToggleModal} from '@/store/main/mutations';

const cfg = { coin: 'USDT', base: 'USDT' };

export default {
  name: 'CoinAction',
  props: ['data', 'symbol'],
  computed: {
    url() {
      return '#!/' + cfg.name + '/' + this.data + ((typeof(this.symbol) === 'string') ? ('/'+this.symbol) : '');
    },
    title() {
      return this.$t(this.data);
    },
  },
  methods: {
    doIt() {
      console.log(`coin action on symbol ${this.symbol.toLowerCase()}`);
      commitSetSymbol(this.$store, this.symbol.toLowerCase());
      commitToggleModal(this.$store, this.data);
    },
  },
}
</script>

<style scoped>

</style>
