<template>
  <tr class="smart-table__row">
    <td class="smart-table__cell" data-column-id="date"><span>{{date_converted}}</span></td>
    <td class="smart-table__cell" data-column-id="cryptocurrency">
      <div class="cryptocurrency">
        <img class="cryptocurrency__img" :src="require('@/assets/img/coins/'+ symbol.toLowerCase() +'.svg')">
        <div class="cryptocurrency__name ms-2">{{symbol.toUpperCase()}}</div>
      </div>
    </td>
    <td class="smart-table__cell" data-column-id="sum">
      <span :class="type">{{data.amount}}</span>
    </td>
    <td class="smart-table__cell" data-column-id="transaction"><a :href="url" target="_blank">{{data.url}}</a></td>
  </tr>
</template>

<script>
export default {
  name: 'HistoryTransfer',
  props: ['data', 'coins'],

  data() {
    return {
      symbol: this.coins[this.data.coin -1].symbol
    }
  },

  computed: {
    date_converted: function () {
      return this.stamp2date(this.data.stamp);
    },

    url: function () {
      return 'https://' + this.data.url;
    },

    type(){
      return this.data.drt;
      /*if(this.data.type === '+'){
        return {
          plus: true
        }
      }else{
        return {
          minus: true
        }
      }*/
    }
  },
}
</script>

<style scoped>

</style>
